<template>
  <section
    class="template-dashboard-view edit-new-template-view"
    :class="{ mobile: getIsMobile, 'is-close-RS': !getIsRSOpen }"
  >
    <div
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      v-loading.fullscreen.lock="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :fullscreen="true"
    >
      <div class="templates-title-block">
        <el-row style="margin: 0px 0 30px 0">
          <el-col :span="24">
            <el-card shadow="never" style="margin-top: 5px">
              <!-- <el-scrollbar style="height: 500px"> -->
              <el-row>
                <el-col :span="14.5" style="padding: 3px">
                  <div style="display: flex">
                    <div style="margin-right: 60px">
                      <h5 style="font-weight: 400">Template Name</h5>
                      <el-input
                        style="width: 300px"
                        v-model="form.name"
                        placeholder="Enter Template Name"
                      >
                      </el-input>
                    </div>
                    <div>
                      <h5 style="font-weight: 400">Description</h5>
                      <el-input
                        style="width: 300px"
                        v-model="form.description"
                        placeholder="Enter Description"
                        type="textarea"
                        maxlength="500"
                        show-word-limit
                      >
                      </el-input>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8"> </el-col>
              </el-row>
              <!-- </el-scrollbar> -->
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="dialog-footer">
      <p slot="footer">
        <el-button type="primary" @click="createNewTemplate">Save </el-button>
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import EntityHelper from "@/mixins/EntitiesHelper";

import NavigationHelper from "@/mixins/navigationHelper";

export default {
  name: "formTemplates-New-Template",
  data() {
    return {
      loadingText: "",
      loading: false,
      allCustomFields: [
        {
          id: "current_date",
          label: "Time - Current Date",
        },
        {
          id: "current_time",
          label: "Time - Current Time",
        },
        {
          id: "current_date_time",
          label: "Time - Current Date Time",
        },
        {
          id: "redirect_link_entity_data_edit",
          label: "Redirect Link - Company User - Data Edit",
        },
        {
          id: "redirect_link_entity_data_view",
          label: "Redirect Link - Company User - Data View",
        },
        {
          id: "redirect_link_ap_entity_data_edit",
          label: "Redirect Link - Application User - Data Edit",
        },
        {
          id: "redirect_link_ap_entity_data_view",
          label: "Redirect Link - Application User - Data View",
        },
      ],
      form: {
        name: "",
        description: "",
      },
      allMentionableFields : []
    };
  },
  async mounted() {
    console.log("formData", this.formData);
    this.form = {
      name: "",
      description: "",
      ...this.formData,
    };
    this.allMentionableFields = [
        ...this.allFields
    ]
    this.relationalDataForm = {
        ...this.relationalFormData
    }
    console.log("this.allMentionableFields",this.allMentionableFields,this.allFields)
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("entityEmailTemplate", [
      "getAllCompanyEmailTemplatesData",
      "getDeleteEmailTemplateStatus",
      "getEmailTemplateError",
      "getCreatedEmailTemplateStatus",
      "getCurrentEmailTemplateData",
      "getUpdatedEmailTemplateStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getUpdateFilterError",
      "getDeleteFilterErros",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getParentEntityRelatiponshipData",
    ]),
    checkFormDisabled() {
      if (
        !this.relationalDataForm.entity_id ||
        !(
          this.relationalDataForm.selectedFields &&
          this.relationalDataForm.selectedFields.length
        )
      ) {
        return true;
      }
      return false;
    },
    checkAlreadyExists() {
      if (this.form.content.includes("[[relationalEntityDataTable]]")) {
        return false;
      }
      return true;
    },
  },
  mixins: [EntityHelper, NavigationHelper],
  props: ["formData","allFields"],
  methods: {
    async createNewTemplate() {
      if (
        !this.form.name.trim() ||
        !this.form.description.trim() ||
        !this.form.subject.trim()
      ) {
        return this.$message.warning(
          "Only spaces are not allowed.Please enter text"
        );
      }
      let labelAndFields = {};
      console.log("allFields",this.allMentionableFields);
      this.allMentionableFields.map((e) => {
        labelAndFields[e.label] = e;
      });
      let isSubjectValid = true;
      let notSupportedSubjectTypes = [
        "IMAGE",
        "SIGNATURE",
        "PAY_BUTTON",
        "FILE",
        "DATA_TABLE",
        "MULTI_LINE_TEXT",
      ];
      let pattern = /\[\[([^[\]]*)\]\]/g;
      let mentionedSubjectLabels = [];
      let match;

      while ((match = pattern.exec(this.form.subject)) !== null) {
        mentionedSubjectLabels.push(match[1]);
      }
      mentionedSubjectLabels.map((e) => {
        const template = labelAndFields[e];
        if (
          template &&
          template.input_type &&
          notSupportedSubjectTypes.includes(template.input_type)
        ) {
          isSubjectValid = false;
        }
      });
      if (!isSubjectValid) {
        return this.$message.warning("Your subject has invalid fields.");
      }
      if (!(this.form.content && this.form.content.length > 20)) {
        return this.$message.warning(
          "Email body must be greater than 20 characters"
        );
      }
      const leftBracesCount = this.form.content.split("[[").length - 1;
      const rightBracesCount = this.form.content.split("]]").length - 1;
      if (leftBracesCount < rightBracesCount) {
        return this.$message.warning("Please remove unwanted ]]");
      } else if (leftBracesCount > rightBracesCount) {
        return this.$message.warning("Please remove unwanted [[");
      }
      const regex = /\[\[\s*]]/g;
      if (regex.test(this.form.content)) {
        return this.$message.warning("There should be field inside [[ ]]");
      }
      // Check for [[ before ]]
      if (/\[\[[^\]]*\[\[/.test(this.form.content)) {
        return this.$message.warning("No [[ allowed inside double brackets");
      }
      const leftParenthesesCount = this.form.content.split("((").length - 1;
      const rightParenthesesCount = this.form.content.split("))").length - 1;

      if (leftParenthesesCount < rightParenthesesCount) {
        return this.$message.warning("Please remove unwanted ))");
      } else if (leftParenthesesCount > rightParenthesesCount) {
        return this.$message.warning("Please remove unwanted ((");
      }

      const regexParentheses = /\(\(\s*\)\)/g;
      if (regexParentheses.test(this.form.content)) {
        return this.$message.warning("There should be content inside (( ))");
      }

      // Check for (( before ))
      if (/\(\((^\))*\(\(/.test(this.form.content)) {
        return this.$message.warning("No (( allowed inside double parentheses");
      }

      this.loadingText = "Creating Your Template";
      this.loading = true;
      //   if (!this.isEdit) {
      let params = {
        ...this.form,
        type: "ENTITY",
      };
      if (
        this.form.content.includes("[[relationalEntityDataTable]]") &&
        this.relationalDataForm.entity_id &&
        this.relationalDataForm.selectedFields &&
        this.relationalDataForm.selectedFields.length
      ) {
        params["form"] = {
          ...this.relationalDataForm,
          type : "ENTITY"
        };
      }
      console.log("this.form zzz",this.form);
      await this.$store.dispatch(
        "entityEmailTemplate/createEmailTemplate",
        params
      );
      if (this.getCreatedEmailTemplateStatus) {
        this.loading = false;
        this.$message.success("Template Created Successfully");
        this.$emit("handleEmailTemplateCreated");
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: this.getEmailTemplateError || "Error creating template",
        });
      }
        // }
    },
  },
};
</script>
<style scoped>
.centered-col {
  margin-top: 5px;
}
.el-textarea__inner {
  min-height: 40px !important;
}
.dialog-footer {
  width: 120%;
  display: flex;
  float: right;
  margin-right: -20px;
  padding: 8px;
  background-color: #eee;
  justify-content: end;
  border-top: 1px solid #ccc;
}
</style>
